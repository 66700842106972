<template>
  <div class="site-settings">
    <b-card>
      <spinner v-if="!siteSettings || loading" />
      <template v-else>
        <form-generator
          :elements="elements"
          :data="siteSettings"
          :handleUpdate="handleUpdate"
        />
      </template>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormGenerator from "../../../components/form-generator.vue";

export default {
  components: { FormGenerator },
  name: "site-settings",
  data() {
    return {
      localSiteSettings: null,
      loading: false
    };
  },
  mounted() {
    this.$store.dispatch("siteSettings/get");
  },
  computed: {
    ...mapState("siteSettings", ["siteSettings"]),
    elements() {
      return [
        {
          key: "promotion_min_days",
          label: "promotion-min-days",
          type: "number"
        },
        {
          text: "save",
          type: "button",
          className: "float-right mt-3 px-4",
          events: {
            click: this.update
          }
        }
      ];
    }
  },
  methods: {
    async update() {
      this.loading = true;

      try {
        await this.$store.dispatch("siteSettings/update", this.siteSettings);
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    handleUpdate({ key }, v) {
      const siteSettings = this.siteSettings;
      siteSettings[key] = v;
      this.$store.commit("siteSettings/SAVE_SITE_SETTINGS", siteSettings);
    }
  }
};
</script>

<style></style>
